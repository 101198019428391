// UserModalComponent.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import { Controller } from "react-hook-form";

const EditUserModalComponent = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  defaultValues,
  errors,
  register,
  control,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} dir="rtl">
      <DialogTitle>ویرایش اطلاعات کاربر {defaultValues.user}</DialogTitle>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ display: "none" }}>
              <FormControl fullWidth>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={defaultValues.id}
                  type="number"
                  render={({ field }) => (
                    <TextField label="ID" {...field} disabled />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="username"
                  shrink={!!defaultValues.first_name}
                >
                  نام
                </InputLabel>

                <TextField
                  id="first_name"
                  margin="none"
                  autoComplete="text"
                  autoFocus
                  disabled={isLoading}
                  defaultValue={defaultValues.first_name ?? ""}
                  error={errors?.first_name && true}
                  {...register(`first_name`, {
                    required: true,
                  })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="last_name"
                  shrink={!!defaultValues.last_name}
                >
                  نام خانوادگی
                </InputLabel>

                <TextField
                  id="last_name"
                  margin="none"
                  autoComplete="text"
                  autoFocus
                  disabled={isLoading}
                  defaultValue={defaultValues.last_name ?? ""}
                  error={errors?.last_name && true}
                  {...register(`last_name`, {
                    required: true,
                  })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="کد ملی"
                  fullWidth
                  autoComplete="text"
                  type="tel"
                  defaultValue={defaultValues.national_id}
                  error={errors?.national_id && true}
                  {...register("national_id", {
                    minLength: {
                      value: 10,
                      message: "کد ملی باید حداقل 10 کاراکتر باشد.",
                    },
                    maxLength: {
                      value: 11,
                      message: "کد ملی نباید بیشتر از 11 کاراکتر باشد.",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "کد ملی باید یک مقدار صحیح 0 یا بالاتر باشد.",
                    },
                  })}
                  helperText={errors?.national_id && errors.national_id.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="شماره دانشجویی"
                  fullWidth
                  autoComplete="text"
                  type="tel"
                  defaultValue={defaultValues.student_number}
                  error={errors?.student_number && true}
                  {...register("student_number")}
                  helperText={
                    errors?.student_number && errors.student_number.message
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="شماره تماس"
                  fullWidth
                  autoComplete="text"
                  type="tel"
                  defaultValue={defaultValues.phone_number}
                  error={errors?.phone_number && true}
                  {...register("national_id", {
                    minLength: {
                      value: 10,
                      message: "شماره موبایل باید حداقل 10 کاراکتر باشد.",
                    },
                    maxLength: {
                      value: 11,
                      message: "شماره موبایل نباید بیشتر از 11 کاراکتر باشد.",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message:
                        "شماره موبایل باید یک مقدار صحیح 0 یا بالاتر باشد.",
                    },
                  })}
                  helperText={
                    errors?.phone_number && errors.phone_number.message
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="نام پدر"
                  margin="none"
                  fullWidth
                  autoComplete="text"
                  defaultValue={defaultValues.father_name}
                  disabled={isLoading}
                  error={errors?.father_name && true}
                  {...register(`father_name`)}
                />
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  label="رمز عبور جدید"
                  margin="none"
                  autoComplete="text"
                  type="password"
                  disabled={isLoading}
                  error={errors?.password && true}
                  {...register(`password`)}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} variant="outlined" color="error">
            لغو
          </Button>

          <Button variant="contained" color="success" type="submit">
            ویرایش
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserModalComponent;
