import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RTLWrapper from "./RTLWrapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material";
import { faIR } from "@mui/material/locale";

function Login() {
  const { requestToken } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("expert1");
  const [password, setPassword] = useState("12345678");

  const lightTheme = createTheme({
    direction: "rtl",
    palette: {
      mode: "light",
    },
    typography: {
      fontFamily: ['"IRANSansX"'].join(","),
    },
  });

  const themeWithLocale = useMemo(
    () => createTheme(lightTheme, faIR),
    [lightTheme, faIR]
  );

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();

    try {
      await requestToken(userName, password);
    } catch (error) {
      console.error("Failed to request token:", error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <RTLWrapper>
      <ThemeProvider theme={themeWithLocale}>
        <Box
          component="main"
          sx={{
            display: "flex",
            flex: "1 1 auto",
            height: "100vh",
          }}
        >
          <Grid2 sx={{ flex: "1 1 auto" }}>
            <Grid2
              xs={12}
              lg={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    maxWidth: 550,
                    px: 3,
                    py: "50px",
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  <Stack spacing={1} sx={{ mb: 3 }}>
                    <img
                      src="/sadra.png"
                      width={"150px"}
                      style={{ margin: "auto" }}
                    />

                    <Typography variant="h6">ورود به حساب کاربری</Typography>
                  </Stack>

                  <form noValidate onSubmit={handlePhoneSubmit}>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="نام کاربری"
                        name="username"
                        type="text"
                        autoComplete="username"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />

                      <TextField
                        fullWidth
                        label="رمز عبور"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Stack>

                    <Button
                      fullWidth
                      size="large"
                      sx={{ mt: 3 }}
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        "ورود"
                      )}
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </ThemeProvider>
    </RTLWrapper>
  );
}

export default Login;
