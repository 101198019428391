import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ForumIcon from "@mui/icons-material/Forum";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import GroupIcon from "@mui/icons-material/Group";
import { Link, Outlet } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { orange, red, blueGrey, deepOrange } from "@mui/material/colors";
import { Cookies } from "react-cookie";
import { faIR } from "@mui/material/locale";
import { useMemo } from "react";
import { ListItem, Skeleton } from "@mui/material";
import RTLWrapper from "../components/RTLWrapper";
import { useAuth } from "../hooks/useAuth";

const drawerWidth = 240;

const Layout = (props) => {
  const cookies = new Cookies();
  const { user, logout } = useAuth();

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [openBillboards, setOpenBillboards] = useState(false);

  const lightTheme = createTheme({
    direction: "rtl",
    palette: {
      mode: "light",
    },
    typography: {
      fontFamily: ['"IRANSansX"'].join(","),
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {},
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 15,
            boxShadow:
              "0px 5px 22px rgba(0, 0, 0, 0.06), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 15,
          },
        },
      },
    },
  });

  const themeWithLocale = useMemo(
    () => createTheme(lightTheme, faIR),
    [lightTheme, faIR]
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Toolbar children={<img src="/sadra.png" />} />

      <Divider sx={{ background: "rgba(250, 250, 250, 0.2);" }} />

      {!user !== true ? (
        <List sx={{ color: "#da3635" }} component="nav">
          {/* Billboards menu */}
          {/* <ListItemButton onClick={() => setOpenBillboards(!openBillboards)}>
          <ListItemIcon>
            <BroadcastOnHome sx={{ color: "#fff" }} />
          </ListItemIcon>

          <ListItemText sx={{ color: "#fff" }} primary="فهرست سطح یک" />
          {openBillboards ? (
            <ExpandLess sx={{ color: "#fff" }} />
          ) : (
            <ExpandMore sx={{ color: "#fff" }} />
          )}
        </ListItemButton>

        <Collapse in={openBillboards} timeout="auto" unmountOnExit>
          <Link to="/billboards">
            <List component="div" sx={{ pr: 2 }} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ViewHeadline sx={{ color: "#fff" }} />
                </ListItemIcon>

                <ListItemText primary="لیست سازه‌ها" />
              </ListItemButton>
            </List>
          </Link>
        </Collapse> */}

          {user.role === "Admin" && (
            <>
              <Link to="/complaint">
                <ListItemButton onClick={() => setOpenSupport(!openSupport)}>
                  <ListItemIcon>
                    <FeedbackIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>

                  <ListItemText sx={{ color: "#fff" }} primary="شکایات" />
                </ListItemButton>
              </Link>

              <Link to="/survey">
                <ListItemButton onClick={() => setOpenSupport(!openSupport)}>
                  <ListItemIcon>
                    <ForumIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>

                  <ListItemText sx={{ color: "#fff" }} primary="نظرات" />
                </ListItemButton>
              </Link>

              <Link to="/users">
                <ListItemButton onClick={() => setOpenSupport(!openSupport)}>
                  <ListItemIcon>
                    <GroupIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>

                  <ListItemText sx={{ color: "#fff" }} primary="کاربران" />
                </ListItemButton>
              </Link>
            </>
          )}

          <Link to="/requests">
            <ListItemButton onClick={() => setOpenSupport(!openSupport)}>
              <ListItemIcon>
                <FolderCopyIcon sx={{ color: "#fff" }} />
              </ListItemIcon>

              <ListItemText sx={{ color: "#fff" }} primary="درخواست‌ها" />
            </ListItemButton>
          </Link>

          <Link to="/tickets">
            <ListItemButton onClick={() => setOpenSupport(!openSupport)}>
              <ListItemIcon>
                <LiveHelpIcon sx={{ color: "#fff" }} />
              </ListItemIcon>

              <ListItemText sx={{ color: "#fff" }} primary="پشتیبانی" />
            </ListItemButton>
          </Link>

          <Divider sx={{ background: orange[200] }} />

          <Link to="/logout" onClick={logout}>
            <ListItemButton sx={{ color: red[400] }}>
              <ListItemIcon>
                <LockIcon sx={{ color: red[400] }} />
              </ListItemIcon>

              <ListItemText
                sx={{ color: red[400] }}
                primary="خروج از حساب کاربری"
              />
            </ListItemButton>
          </Link>
        </List>
      ) : (
        <List sx={{ color: "#da3635" }} component="nav">
          <ListItem>
            <ListItemIcon>
              <Skeleton width="50px" style={{ backgroundColor: "#455A64" }} />
            </ListItemIcon>

            <ListItemText
              primary={<Skeleton style={{ backgroundColor: "#455A64" }} />}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Skeleton width="50px" style={{ backgroundColor: "#455A64" }} />
            </ListItemIcon>

            <ListItemText
              primary={<Skeleton style={{ backgroundColor: "#455A64" }} />}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Skeleton width="50px" style={{ backgroundColor: "#455A64" }} />
            </ListItemIcon>

            <ListItemText
              primary={<Skeleton style={{ backgroundColor: "#455A64" }} />}
            />
          </ListItem>
        </List>
      )}
    </Box>
  );

  return (
    <RTLWrapper>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />

        <Box>
          <AppBar
            position="fixed"
            sx={{
              backdropFilter: "blur(6px)",
              background: "#263238",
              width: { md: `calc(100% - ${drawerWidth}px)` },
              ml: { md: `${drawerWidth}px` },
              pl: { md: 2 },
            }}
          >
            <Toolbar dir="rtl">
              <IconButton
                aria-label="open drawer"
                edge="start"
                color="#fff"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" }, color: "#fff" }}
              >
                <MenuIcon />
              </IconButton>

              <small>
                {user && user.first_name + " " + user.last_name} عزیز خوش آمدید!
              </small>
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              anchor="left"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { sm: "block", md: "none" },
                flexShrink: 0,
                // transform: "translateX(260px)",
                "& .MuiDrawer-paper": {
                  background: blueGrey[900],
                  boxSizing: "border-box",
                  width: drawerWidth,
                  borderLeft: "1px solid rgba(250, 250, 250, 0.2)",
                },
              }}
            >
              {drawer}
            </Drawer>

            <Drawer
              anchor="left"
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  background: blueGrey[900],
                  boxSizing: "border-box",
                  width: drawerWidth,
                  borderLeft: "1px solid rgba(250, 250, 250, 0.2)",
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>

        <Box
          sx={{
            paddingTop: "80px",
            ml: { md: `${drawerWidth}px` },
            px: 2,
            pb: 2,
          }}
        >
          <Outlet />
        </Box>
      </ThemeProvider>
    </RTLWrapper>
  );
};

export default Layout;
