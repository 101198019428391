import { Avatar, Rating, Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import moment from "jalali-moment";
import {
  getGlobalHeader,
  API_GET_EXPERTS_LIST,
  API_GET_SURVEY_EXPERT_DETAIL,
} from "../../api";
import { errorToast, successToast } from "../../helpers/utils";
import { useAuth } from "../../hooks/useAuth";

function SurveyExpertSinglePage() {
  const params = useParams();
  const id = params.id;
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [experts, setExperts] = useState([]);
  const [page, setPage] = useState(1);
  // State variable to manage the visibility of a modal, initialized as false
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [requestStatus, setRequestStatus] = useState("add-file");

  const getRequestData = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_SURVEY_EXPERT_DETAIL(id), {
        headers: { ...getGlobalHeader() },
      });

      console.log(response);

      setData(response.data.result);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const getExpertsList = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_EXPERTS_LIST(), {
        headers: { ...getGlobalHeader() },
      });

      setExperts(response.data.results);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRequestData();

    getExpertsList();
  }, []);

  return (
    <Box
      sx={{ maxWidth: "100%", mx: "auto", mt: 1, textAlign: "center" }}
      dir="rtl"
    >
      {experts ? (
        experts
          .filter((item) => item.id.toString() === id)
          .map((item) => {
            return (
              <Box key={item.id}>
                <Avatar
                  src={item.avatar}
                  sx={{ mx: "auto", mb: 2, width: 100, height: 100 }}
                />

                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  {item.first_name + " " + item.last_name}
                </Typography>
              </Box>
            );
          })
      ) : (
        <>
          <Skeleton width={100} height={100} circle sx={{ mx: "auto" }} />

          <Skeleton width={"20%"} sx={{ mx: "auto" }} />
        </>
      )}

      <Box sx={{ mt: 3 }}>
        {data && data.rating ? (
          <>
            <Rating
              name="half-rating"
              value={parseFloat(data.rating) || 0}
              precision={0.1}
              readOnly
              size="large"
            />
            <Box sx={{ ml: 2 }}>امتیاز کل: {parseFloat(data.rating)}</Box>
          </>
        ) : (
          <>
            <Skeleton width={"30%"} sx={{ mx: "auto" }} />
            <Skeleton width={"10%"} sx={{ mx: "auto" }} />
          </>
        )}
      </Box>
    </Box>
  );
}

export default SurveyExpertSinglePage;
