import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, getGlobalHeader } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [user, setUser] = useState("");

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);

      setTimeout(() => {
        getUserInfo();
      }, 500);
    }
  }, [accessToken]);

  const requestToken = async (username, password) => {
    try {
      const response = await axios.post(`${apiUrl}/expert/expert_login/`, {
        username: username,
        password: password,
      });

      setAccessToken(response.data.token);

      navigate("/"); // Redirect to home page after successful login
    } catch (error) {
      console.error("Error requesting token:", error);
      throw error;
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${apiUrl}/account/user_profile/`, {
        headers: { ...getGlobalHeader() },
      });

      setUser(response.data.detail);
    } catch (error) {
      if (error.response.status === 401) {
        logout();
      } else {
        toast.error("خطایی در سیستم داریم!");
      }
      throw error;
    }
  };

  const logout = () => {
    setAccessToken(null);

    localStorage.removeItem("accessToken");

    axios.defaults.headers.common["Authorization"] = null;

    navigate("/login"); // Optionally redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ accessToken, requestToken, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
}
