import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const removeEmptyFields = (data) => {
  Object.keys(data).forEach((key) => {
    if (key !== "managers") {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
      if (["string", "number", "undefined"].includes(typeof data[key])) {
        if (data[key] === "" || data[key] === null) {
          delete data[key];
        }
      } else {
        if (data[key] && data[key].length !== 0) {
          for (let i = 0; i < data[key].length; i++) {
            Object.keys(data[key][i]).forEach((key2) => {
              if (data[key][i][key2] === "" || data[key][i][key2] == null) {
                delete data[key][i][key2];
              }
            });
          }
        } else {
          delete data[key];
        }
      }
    }
  });
};

export const successToast = (data) => {
  toast.success(data, {
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    rtl: true,
  });
};

export const warnToast = (data) => {
  toast.warn(data, {
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    rtl: true,
  });
};

export const errorToast = (data) => {
  toast.error(data, {
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    rtl: true,
  });
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const showStatusStracture = (data) => {
  if (data === "billboards_count") {
    return "تعداد سازه‌ها";
  } else if (data === "folded") {
    return "مشکل چروکی";
  } else if (data === "lighting") {
    return "مشکل نور";
  } else if (data === "free") {
    return "سازه‌های بدون کاربر";
  } else if (data === "healthy") {
    return "سازه‌های بدون مشکل";
  } else if (data === "online") {
    return "سازه‌های متصل";
  } else if (data === "torn") {
    return "مشکل پارگی";
  } else if (data === "troubled") {
    return "مشکل‌دار";
  } else if (data === "wrinkled") {
    return "مشکل چین خوردگی";
  } else {
    return data;
  }
};
