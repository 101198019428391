import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import moment from "jalali-moment";
import {
  API_GET_ADMIN_SINGLE_REQUESTS,
  API_EXPERT_UPDATE_REQUESTS,
  getGlobalHeader,
  API_GET_EXPERTS_LIST,
  API_EXPERT_ADD_EXPERT_REQUESTS,
  API_EXPERT_DOCUMENT,
} from "../../api";
import { errorToast, successToast } from "../../helpers/utils";
import { Controller, useForm } from "react-hook-form";
import queryString from "query-string";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

function RequestSinglePage() {
  const params = useParams();
  const id = params.id;
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [experts, setExperts] = useState([]);
  const [page, setPage] = useState(1);
  // State variable to manage the visibility of a modal, initialized as false
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [requestStatus, setRequestStatus] = useState("add-file");

  const getRequestData = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_ADMIN_SINGLE_REQUESTS(id), {
        headers: { ...getGlobalHeader() },
      });

      setData(response.data.result);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const getExpertsList = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_EXPERTS_LIST(), {
        headers: { ...getGlobalHeader() },
      });

      setExperts(response.data.results);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const handleUpdateRequest = async (formData) => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        API_EXPERT_UPDATE_REQUESTS(),
        {
          description: formData.description,
          file_step_id: formData.file_step_id,
          status: formData.status,
          expert_id: user.id,
        },
        {
          headers: { ...getGlobalHeader() },
        }
      );

      successToast("درخواست شما با موفقیت ثبت شد.");

      getRequestData();

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);

      // Handle errors, e.g., validation errors or network issues
      if (error.response.status === 400) {
        errorToast(error.response.data.error);
      }
      console.error("Error:", error.response.data.error, error.response.status);
    }
  };

  const handleAddExpertToRequest = async (formData) => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        API_EXPERT_ADD_EXPERT_REQUESTS(),
        formData,
        {
          headers: { ...getGlobalHeader() },
        }
      );

      successToast("درخواست شما با موفقیت ثبت شد.");

      getRequestData();

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);
      console.error("Error:", error.message);
    }
  };

  const handleAddFileToRequest = async (formData) => {
    const form = new FormData();

    // Append form fields
    form.append("file_step_id", formData.file_step_id);

    // Append file
    form.append("pdf_file", file);

    setTimeout(() => {
      console.log(form);
    }, 500);

    try {
      setIsLoading(true);

      const response = await axios.post(API_EXPERT_DOCUMENT(), form, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...{ ...getGlobalHeader() },
        },
      });

      successToast("درخواست شما با موفقیت ثبت شد.");
      getRequestData();
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      // Handle errors
      setIsLoading(false);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRequestData();

    getExpertsList();
  }, []);

  return (
    <>
      <Box sx={{ maxWidth: "100%", mx: "auto", mt: 1 }} dir="rtl">
        {data && (
          <Box sx={{ mb: 4 }}>
            <Grid2 container>
              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>شماره پرونده:</strong> {data.file_number}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>نوع پرونده:</strong>{" "}
                  {data.title === "upload_documents"
                    ? "آپلود مدارک"
                    : data.title === "dorm"
                    ? "خوابگاه"
                    : data.title === "objected_to_score"
                    ? "اعتراض به نمره"
                    : data.title === "graduation"
                    ? "فارغ التحصیلی"
                    : data.title}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>تاریخ ایجاد:</strong>{" "}
                  {moment
                    .from(data.file_create_time, "YYYY/MM/DD")
                    .locale("fa")
                    .format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Grid2>

              <Grid2 xs={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>وضعیت مرحله پرونده:</strong>{" "}
                  {data.file_step_status === "in_review"
                    ? "در حال بررسی"
                    : data.file_step_status === "not_review"
                    ? "بررسی نشده"
                    : data.file_step_status === "Closed by Admin"
                    ? "بسته شده توسط مدیر"
                    : data.file_step_status}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>تاریخ آخرین به‌روزرسانی:</strong>{" "}
                  {moment
                    .from(data.file_step_last_update, "YYYY/MM/DD")
                    .locale("fa")
                    .format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>تاریخ ایجاد مرحله پرونده:</strong>{" "}
                  {moment
                    .from(data.file_step_create_time, "YYYY/MM/DD")
                    .locale("fa")
                    .format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Grid2>

              {data.document_type && (
                <>
                  <Grid2 size={3}>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      <strong>نوع فایل:</strong> {data.document_type}
                    </Typography>
                  </Grid2>

                  <Grid2 size={3}>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      <strong>فایل:</strong>{" "}
                      {data.pdf_file ? (
                        <a href={data.pdf_file} target="_blank">
                          دانلود فایل
                        </a>
                      ) : (
                        "فایلی آپلود نشده"
                      )}
                    </Typography>
                  </Grid2>
                </>
              )}

              <Grid2 size={12}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>توضیحات کاربر:</strong> {data.customer_description}
                </Typography>
              </Grid2>

              <Grid2 size={12}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>توضیحات کارشناس:</strong> {data.expert_description}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        )}

        <Divider sx={{ mb: 4 }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            mb: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRequestStatus("add-file")}
          >
            آپلود فایل به پرونده
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setRequestStatus("change-status")}
          >
            ویرایش و تغییر حالت پرونده
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setRequestStatus("assign-expert")}
          >
            ارجاع پرونده به کارشناس دیگر
          </Button>
        </Box>

        <Divider sx={{ mb: 4 }} />

        {requestStatus === "add-file" && (
          <Box
            sx={{ mb: 4 }}
            component="form"
            noValidate
            onSubmit={handleSubmit(handleAddFileToRequest)}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Typography variant="h6" gutterBottom>
                  <strong>آپلود فایل به پرونده</strong>
                </Typography>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth sx={{ display: "none" }}>
                  <Controller
                    name="file_step_id"
                    control={control}
                    defaultValue={id}
                    render={({ field }) => (
                      <TextField label="file_step_id" {...field} disabled />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <input
                        type="file"
                        {...field}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  fullWidth
                >
                  ارسال فایل
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        )}

        {requestStatus === "change-status" && (
          <Box
            sx={{ mb: 4 }}
            component="form"
            noValidate
            onSubmit={handleSubmit(handleUpdateRequest)}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Typography variant="h6" gutterBottom>
                  <strong>ویرایش و تغییر حالت پرونده</strong>
                </Typography>
              </Grid2>

              <Grid2 size={12} sx={{ display: "none" }}>
                <FormControl fullWidth>
                  <Controller
                    name="file_step_id"
                    control={control}
                    defaultValue={id}
                    type="hidden"
                    render={({ field }) => (
                      <TextField label="file_step_id" {...field} disabled />
                    )}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <InputLabel>وضعیت درخواست</InputLabel>

                  <Controller
                    name="status"
                    control={control}
                    type="text"
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select {...field} defaultValue={[]}>
                        <MenuItem value={"not_review"}>بررسی نشده</MenuItem>
                        <MenuItem value={"review"}>در حال بررسی</MenuItem>
                        <MenuItem value={"terminated"}>پایان یافته</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <TextField
                    id="description"
                    label="توضیحات"
                    multiline
                    rows={3}
                    {...register("description")}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  type="submit"
                >
                  ارسال
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        )}

        {data && data.file_number && requestStatus === "assign-expert" && (
          <Box
            sx={{ mb: 4 }}
            component="form"
            noValidate
            onSubmit={handleSubmit(handleAddExpertToRequest)}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Typography variant="h6" gutterBottom>
                  <strong>ارجاع پرونده به کارشناس دیگر</strong>
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <FormControl fullWidth>
                  <Controller
                    name="file_number"
                    control={control}
                    defaultValue={data.file_number}
                    type="hidden"
                    render={({ field }) => (
                      <TextField label="file_number" {...field} disabled />
                    )}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={9}>
                <FormControl fullWidth>
                  <InputLabel>کارشناس</InputLabel>

                  <Controller
                    name="expert_id"
                    control={control}
                    type="text"
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select {...field} defaultValue={[]}>
                        {experts.length > 0 &&
                          experts.map((item, index) => {
                            return (
                              <MenuItem value={item.id} key={index}>
                                {item.first_name + " " + item.last_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  type="submit"
                >
                  ارسال
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        )}

        <Divider sx={{ mb: 4 }} />

        <Box sx={{ mb: 4 }}>
          <Grid2 container>
            <Grid2 size="12" sx={{ marginBottom: "5px" }}>
              <Typography variant="h6" gutterBottom>
                <strong>گردش کار پرونده</strong>
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 container spacing={1}>
            {data && data.other_steps && data.other_steps.length > 0
              ? data.other_steps.map((item) => {
                  return (
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={item.id}>
                      <Box
                        sx={{
                          background: "#eee",
                          padding: "15px",
                          borderRadius: "15px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography variant="body1">
                          <strong>تاریخ ایجاد:</strong>{" "}
                          {moment
                            .from(item.create_time, "YYYY/MM/DD")
                            .locale("fa")
                            .format("HH:mm - YYYY/MM/DD")}
                        </Typography>

                        <Typography variant="body1">
                          <strong>تاریخ آخرین به‌روزرسانی:</strong>{" "}
                          {moment
                            .from(item.last_update, "YYYY/MM/DD")
                            .locale("fa")
                            .format("HH:mm - YYYY/MM/DD")}
                        </Typography>

                        <Typography variant="body1">
                          <strong>وضعیت:</strong>{" "}
                          {item.status === "review"
                            ? "در حال بررسی"
                            : item.status === "not_review"
                            ? "بررسی نشده"
                            : item.status === "Closed by Admin"
                            ? "بسته شده توسط مدیر"
                            : item.status}
                        </Typography>

                        <Typography variant="body1">
                          <strong>کارشناس:</strong>{" "}
                          {item.expert__first_name +
                            " " +
                            item.expert__last_name}
                        </Typography>
                      </Box>
                    </Grid2>
                  );
                })
              : "گردش کاری برای نمایش وجود ندارد."}
          </Grid2>
        </Box>

        <Divider sx={{ mb: 4 }} />

        <Box sx={{ background: "#eee", padding: "15px", borderRadius: "15px" }}>
          <Grid2 container>
            <Grid2 size="12" sx={{ marginBottom: "5px" }}>
              <Typography variant="h6" gutterBottom>
                <strong>مدارک کارشناس پرونده</strong>
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            {data && data.expert_docs && data.expert_docs.length > 0
              ? data.expert_docs.map((item, index) => {
                  return (
                    <Grid2 xs={2} key={index}>
                      <CardActionArea sx={{ borderRadius: "15px" }}>
                        <a href={item.pdf_file} target="_blank">
                          <Card>
                            <CardContent
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                rowGap: "21px",
                              }}
                            >
                              <InsertDriveFileIcon
                                fontSize="large"
                                color="#999"
                              />

                              <Typography variant="body1">
                                دانلود فایل
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                      </CardActionArea>
                    </Grid2>
                  );
                })
              : "مدرکی برای نمایش وجود ندارد."}
          </Grid2>
        </Box>
      </Box>
    </>
  );
}

export default RequestSinglePage;
