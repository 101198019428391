import React, { useState, useEffect } from "react";
import { Button, Snackbar } from "@mui/material";

const InstallPWA = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event
      setSnackbarOpen(true); // Show the Snackbar
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Reset the prompt
        setSnackbarOpen(false); // Close Snackbar
      });
    }
  };

  return (
    <Snackbar
      open={isSnackbarOpen}
      message="برای راحتی برنامه را نصب کنید"
      action={
        <Button onClick={handleInstallClick} sx={{ color: "#ef5350" }}>
          نصب
        </Button>
      }
      onClose={() => setSnackbarOpen(false)}
      autoHideDuration={6000}
    />
  );
};

export default InstallPWA;
