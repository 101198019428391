import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import moment from "jalali-moment";
import {
  API_GET_ADMIN_SINGLE_REQUESTS,
  API_EXPERT_UPDATE_REQUESTS,
  global_header,
  API_GET_EXPERTS_LIST,
  API_EXPERT_ADD_EXPERT_REQUESTS,
  API_EXPERT_DOCUMENT,
  API_GET_COMPLAINT_DETAIL,
  API_GET_SURVEY_EXPERT_DETAIL,
  getGlobalHeader,
  API_GET_SINGLE_DETAIL,
} from "../../api";
import { errorToast, successToast } from "../../helpers/utils";
import { Controller, useForm } from "react-hook-form";
import queryString from "query-string";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useAuth } from "../../hooks/useAuth";

function SurveySinglePage() {
  const params = useParams();
  const id = params.id;
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [experts, setExperts] = useState([]);

  const getRequestData = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_SINGLE_DETAIL(id), {
        headers: { ...getGlobalHeader() },
      });

      setData(response.data);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <Box
      sx={{ maxWidth: "100%", mx: "auto", mt: 1, textAlign: "center" }}
      dir="rtl"
    >
      {data && data.expert ? (
        <Box>
          <Avatar sx={{ mx: "auto", mb: 2, width: 100, height: 100 }} />

          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            {data.expert.first_name + " " + data.expert.last_name}
          </Typography>

          {data.created_at ? (
            <Chip
              label={`تاریخ ایجاد: ${moment
                .from(data.created_at, "YYYY/MM/DD")
                .locale("fa")
                .format("YYYY/MM/DD ساعت HH:mm")}`}
              sx={{ mt: 1, mb: 1 }}
            />
          ) : (
            ""
          )}

          <Box sx={{ mb: 2 }}>
            {data.user
              ? `کاربر: ${data.user.first_name + " " + data.user.last_name}`
              : ""}
          </Box>

          <Box>
            {data.description
              ? `توضیحات: ${data.description}`
              : "توضیحاتی برای این نظر ثبت نشده است."}
          </Box>
        </Box>
      ) : (
        <>
          <Skeleton
            width={100}
            height={100}
            circle="true"
            sx={{ mx: "auto" }}
          />

          <Skeleton width={"20%"} sx={{ mx: "auto" }} />
        </>
      )}

      <Box sx={{ mt: 3 }}>
        {data && data.rating ? (
          <>
            <Rating
              name="half-rating"
              value={parseFloat(data.rating)}
              precision={0.1}
              readOnly
              size="large"
            />
            <Box sx={{ ml: 2 }}>کل: {data.rating}</Box>
          </>
        ) : (
          <>
            <Skeleton width={"30%"} sx={{ mx: "auto" }} />
            <Skeleton width={"10%"} sx={{ mx: "auto" }} />
          </>
        )}
      </Box>
    </Box>
  );
}

export default SurveySinglePage;
