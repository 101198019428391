// export const apiUrl = "http://62.3.41.6:3000";
// export const apiUrl = "http://192.168.100.94:8000";
export const apiUrl = "https://api.avayes.com";
// export const apiUrl = "http://avayes.msoundless.com";


export const global_header = {
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

export const getGlobalHeader = () => {
  return {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Token is retrieved dynamically
  };
};

export const API_GET_USERS_LIST = (params) => `${apiUrl}/account/users_list${params ? `?${params}` : ""}`;
export const API_UPDATE_USER = () => `${apiUrl}/account/update_user/`;
export const API_CREATE_USER = `${apiUrl}/account/create_user/`;
export const API_DELETE_USER = `${apiUrl}/account/delete_user/`;

export const API_GET_COMPLAINT_LIST = (params) => `${apiUrl}/expert/complaints_list${params ? `?${params}` : ""}`;
export const API_GET_COMPLAINT_DETAIL = (id) => `${apiUrl}/expert/complaint_detail${id ? `?id=${id}` : ""}`;

export const API_GET_SURVEYS_LIST = (params) => `${apiUrl}/customer/survey_list${params ? `?${params}` : ""}`;
export const API_GET_SURVEY_EXPERT_DETAIL = (id) => `${apiUrl}/customer/get_expert_survey_rating${id ? `?expert_id=${id}` : ""}`;
export const API_GET_SINGLE_DETAIL = (id) => `${apiUrl}/customer/get_survey/${id}`;

export const API_GET_EXPERTS_LIST = (params) => `${apiUrl}/expert/experts_list${params ? `?${params}` : ""}`;
export const API_GET_EXPERT_REQUESTS = (params) => `${apiUrl}/expert/expert_file_steps${params ? `?${params}` : ""}`;
export const API_GET_ADMIN_REQUESTS = (params) => `${apiUrl}/expert/admin_file_steps${params ? `?${params}` : ""}`;
export const API_EXPERT_UPDATE_REQUESTS = () => `${apiUrl}/expert/change_file_step_status/`;
export const API_EXPERT_DOCUMENT = () => `${apiUrl}/expert/create_document/`;
export const API_EXPERT_ADD_EXPERT_REQUESTS = () => `${apiUrl}/expert/add_expert_to_filestep/`;
export const API_GET_ADMIN_SINGLE_REQUESTS = (id) => `${apiUrl}/expert/expert_file_steps_detail/?file_step_id=${id}`;
export const API_UPDATE_REQUEST = (id) => `${apiUrl}/expert/update_complaint/`;

export const API_GET_ADMIN_TICKET_MESSAGES = (params) => `${apiUrl}/chat/admin_chat_list${params ? `?${params}` : ""}`;
export const API_GET_EXPERT_TICKET_MESSAGES = (params) => `${apiUrl}/chat/chat_list${params ? `?${params}` : ""}`;
export const API_GET_TICKET_MESSAGES = (id) => `${apiUrl}/chat/chat_messages/?chat_id=${id}`;
export const API_SEND_TICKET_MESSAGE = () => `${apiUrl}/chat/send_message/`;
