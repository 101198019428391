import {
  Button,
  CircularProgress,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  API_GET_ADMIN_TICKET_MESSAGES,
  API_GET_EXPERT_TICKET_MESSAGES,
  getGlobalHeader,
} from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { errorToast, successToast } from "../../helpers/utils";
import { red } from "@mui/material/colors";
import { Cookies } from "react-cookie";
import moment from "jalali-moment";
import { useAuth } from "../../hooks/useAuth";

const TicketsListPage = () => {
  const { user } = useAuth();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const columns = [
    {
      field: "actions",
      headerName: "عملیات",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.id && (
              <Tooltip title="مشاهده تیکت">
                <Link to={`/tickets/show/${params.row.id}`}>
                  <IconButton aria-label="editUser">
                    <VisibilityIcon
                      index={params.row.id}
                      sx={{ color: "#999" }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان",
      width: 200,
    },
    {
      field: "status",
      headerName: "وضعیت",
      width: 200,
      valueGetter: (status) =>
        status === "checking"
          ? "بررسی"
          : status === "answered"
          ? "پاسخ داده شده"
          : status === "ended"
          ? "پایان یافته"
          : status,
    },
    {
      field: "create_time",
      headerName: "زمان ایجاد",
      width: 200,
      valueGetter: (create_time) =>
        create_time &&
        moment
          .from(create_time, "YYYY/MM/DD")
          .locale("fa")
          .format("HH:mm - YYYY/MM/DD"),
    },
  ];

  const handleGetTickets = async () => {
    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(
        user.role === "Admin"
          ? API_GET_ADMIN_TICKET_MESSAGES()
          : API_GET_EXPERT_TICKET_MESSAGES(),
        {
          headers: { ...getGlobalHeader() },
        }
      );

      // Handle the response as needed
      setTickets(response.data.results);

      setTotalCount(response.data.count);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues

      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }

    setIsFirstLoad(false);
  };

  useEffect(() => {
    handleGetTickets();
  }, [page]);

  return (
    <>
      <Box sx={{ mt: 1 }} dir="rtl">
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            {isFirstLoad ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : tickets?.length > 0 ? (
              <Box sx={{ width: "100%", display: "grid" }}>
                <DataGrid
                  rows={tickets}
                  columns={columns}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  loading={isLoading}
                  rowCount={totalCount}
                  autoPageSize
                  pagination
                  page
                  paginationMode="server"
                  onPaginationModelChange={(newPage) => {
                    setPage(newPage.page);
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                تیکتی یافت نشد.
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default TicketsListPage;
