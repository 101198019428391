import {
  Avatar,
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  API_GET_EXPERTS_LIST,
  API_GET_SURVEYS_LIST,
  getGlobalHeader,
} from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useForm } from "react-hook-form";
import { errorToast, successToast } from "../../helpers/utils";
import { red } from "@mui/material/colors";
import { Cookies } from "react-cookie";
import moment from "jalali-moment";
import queryString from "query-string";

const SurveysListPage = () => {
  const [firstLoad, isFirstLoad] = useState(true);
  const [survyes, setSurveys] = useState([]);
  const [experts, setExperts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1); // Assume at least 1 page
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = [
    {
      field: "actions",
      headerName: "عملیات",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.id && (
              <Tooltip title="مشاهده درخواست">
                <Link to={`/survey/show/${params.row.id}`}>
                  <IconButton aria-label="showComplaint">
                    <VisibilityIcon
                      index={params.row.id}
                      sx={{ color: "#999" }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "کاربر",
      width: 300,
      valueGetter: (user) => user && user.first_name + " " + user.last_name,
    },
    {
      field: "expert",
      headerName: "کارشناس",
      width: 300,
      renderCell: (params) =>
        params.row.expert
          ? params.row.expert.first_name + " " + params.row.expert.last_name
          : null,
    },
    {
      field: "rating",
      headerName: "امتیاز",
      width: 200,
    },
    {
      field: "created_at",
      headerName: "تاریخ ایجاد",
      width: 200,
      valueGetter: (created_at) =>
        created_at &&
        moment
          .from(created_at, "YYYY/MM/DD")
          .locale("fa")
          .format("HH:mm - YYYY/MM/DD"),
    },
  ];

  useEffect(() => {
    handleGetSurveys();
  }, [page, pageSize]);

  useEffect(() => {
    getExpertsList();
  }, []);

  useEffect(() => {
    if (error === 403) {
      errorToast("دسترسی شما به این صفحه مسدود شده است.");
    }
  }, [error]);

  const handleGetSurveys = async () => {
    const stringified = queryString.stringify(
      { page, page_size: pageSize },
      { skipEmptyString: true }
    );

    try {
      setIsLoading(true);

      setError(null);

      const response = await axios.get(API_GET_SURVEYS_LIST(stringified), {
        headers: { ...getGlobalHeader() },
      });

      setSurveys(response.data.results);

      setTotalCount(response.data.count);

      setTotalPages(Math.ceil(response.data.count / pageSize));
    } catch (error) {
      setError(error.response.status);
    } finally {
      setIsLoading(false);
    }

    isFirstLoad(false);
  };

  const getExpertsList = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_EXPERTS_LIST(), {
        headers: { ...getGlobalHeader() },
      });

      setExperts(response.data.results);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handlePageChange = debounce((newPage) => {
    setPage(newPage.page + 1);
  }, 300);

  return (
    <>
      <Box sx={{ mt: 1 }} dir="rtl">
        <Grid2 container spacing={2}>
          {experts.map((item) => {
            return (
              <Grid2 size={3} key={item.id}>
                <Box
                  sx={{ textAlign: "center", background: "#eee", p: 3, mb: 2 }}
                >
                  <Link to={`/survey/expert/show/${item.id}`}>
                    <Avatar sx={{ mb: 2, mx: "auto" }} />

                    {item.first_name + " " + item.last_name}
                  </Link>
                </Box>
              </Grid2>
            );
          })}

          <Grid2 size={12}>
            {firstLoad ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : survyes?.length > 0 ? (
              <Box sx={{ width: "100%", display: "grid" }}>
                <DataGrid
                  rows={survyes}
                  columns={columns}
                  pageSizeOptions={[pageSize]}
                  pagination
                  page
                  paginationMode="server"
                  disableColumnMenu
                  autoPageSize
                  rowCount={totalCount}
                  loading={isLoading}
                  onPaginationModelChange={(newPage) => {
                    handlePageChange(newPage);
                  }}
                />
              </Box>
            ) : error === 403 ? (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                دسترسی شما به این صفحه مسدود شده است.
              </Box>
            ) : (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                نظری یافت نشد.
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default SurveysListPage;
