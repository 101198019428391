import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./context/AuthContext";
import Login from "./components/Login";
import Layout from "./pages/_Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import TicketsListPage from "./pages/tickets";
import TicketMessagesPage from "./pages/tickets/show";
import RequestsListPage from "./pages/requests";
import RequestSinglePage from "./pages/requests/show";
import ComplaintsListPage from "./pages/complaint";
import ComplaintSinglePage from "./pages/complaint/show";
import SurveysListPage from "./pages/survey";
import SurveyExpertSinglePage from "./pages/survey/showExpert";
import InstallPWA from "./components/InstallPWA";
import SurveySinglePage from "./pages/survey/show";
import UsersListPage from "./pages/users";
import UserCreatePage from "./pages/users/create";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<Layout />}>
            <Route
              index
              element={<ProtectedRoute>{/* <HomePage /> */}</ProtectedRoute>}
            />
          </Route>

          <Route path="/tickets" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <TicketsListPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="tickets/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <TicketMessagesPage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/requests" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <RequestsListPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="requests/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <RequestSinglePage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/complaint" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <ComplaintsListPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="complaint/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <ComplaintSinglePage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/survey" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <SurveysListPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="survey/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <SurveySinglePage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="survey/expert/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <SurveyExpertSinglePage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/users" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <UsersListPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="users/create" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <UserCreatePage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="users/show/:id" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <ComplaintSinglePage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Redirect any other path to the root */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <ToastContainer />
      </AuthProvider>

      <InstallPWA />
    </Router>
  );
}

export default App;

// import DashboardRoutes from "./routes";
// import { setAuthToken } from "./helpers/setAuthToken";
// import { ToastContainer } from "react-toastify";
// import "./App.css";
// import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
// import { SwipeableDrawer } from "@mui/material";

// function App() {
//   //check jwt token
//   const token = localStorage.getItem("token");
//   if (token) {
//     setAuthToken(token);
//   }

//   const params = {
//     translate: {
//       headline: "نصب برنامه",
//       buttonInstall: "نصبش کن",
//       chromiumAddHomeScreen: "Add to",
//     },
//   };

//   return (
//     <div className="App">
//       <DashboardRoutes />

//       <ToastContainer />

//       {/* <SwipeableDrawer
//         container={container}
//         anchor="bottom"
//         open={open}
//         onClose={toggleDrawer(false)}
//         onOpen={toggleDrawer(true)}
//         swipeAreaWidth={drawerBleeding}
//         disableSwipeToOpen={false}
//         ModalProps={{
//           keepMounted: true,
//         }}
//       >
//         <StyledBox
//           sx={{
//             position: "absolute",
//             top: -drawerBleeding,
//             borderTopLeftRadius: 8,
//             borderTopRightRadius: 8,
//             visibility: "visible",
//             right: 0,
//             left: 0,
//           }}
//         >
//           <Puller />
//           <Typography sx={{ p: 2, color: "text.secondary" }}>
//             51 results
//           </Typography>
//         </StyledBox>
//         <StyledBox
//           sx={{
//             px: 2,
//             pb: 2,
//             height: "100%",
//             overflow: "auto",
//           }}
//         >
//           <Skeleton variant="rectangular" height="100%" />
//         </StyledBox>
//       </SwipeableDrawer> */}
//     </div>
//   );
// }

// export default App;
