import { useEffect, useState } from "react";
import { CircularProgress, Grid2, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { getGlobalHeader, API_GET_COMPLAINT_LIST } from "../../api";
import { errorToast } from "../../helpers/utils";
import Box from "@mui/material/Box";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "jalali-moment";
import queryString from "query-string";

const ComplaintsListPage = () => {
  const [firstLoad, isFirstLoad] = useState(true);
  const [complaints, setComplaints] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1); // Assume at least 1 page
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = [
    {
      field: "actions",
      headerName: "عملیات",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.id && (
              <Tooltip title="مشاهده درخواست">
                <Link to={`/complaint/show/${params.row.id}`}>
                  <IconButton aria-label="showComplaint">
                    <VisibilityIcon
                      index={params.row.id}
                      sx={{ color: "#999" }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "کاربر",
      width: 200,
      valueGetter: (user) => user && user.first_name + " " + user.last_name,
    },
    {
      field: "status",
      headerName: "وضعیت",
      width: 200,
      valueGetter: (status) =>
        status === "in_review"
          ? "در حال بررسی"
          : status === "approved"
          ? "تایید"
          : status === "unsuccsessful"
          ? "عدم تایید"
          : status,
    },
    {
      field: "created_at",
      headerName: "تاریخ ایجاد",
      width: 200,
      valueGetter: (created_at) =>
        created_at &&
        moment
          .from(created_at, "YYYY/MM/DD")
          .locale("fa")
          .format("HH:mm - YYYY/MM/DD"),
    },
  ];

  const handleGetComplaints = async () => {
    const stringified = queryString.stringify(
      { page, page_size: pageSize },
      { skipEmptyString: true }
    );

    try {
      setIsLoading(true);

      setError(null);

      const response = await axios.get(API_GET_COMPLAINT_LIST(stringified), {
        headers: getGlobalHeader(),
      });

      setComplaints(response.data.results);

      setTotalCount(response.data.count);

      setTotalPages(Math.ceil(response.data.count / pageSize));
    } catch (error) {
      setError(error.response.status);
    } finally {
      setIsLoading(false);
    }

    isFirstLoad(false);
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handlePageChange = debounce((newPage) => {
    setPage(newPage.page + 1);
  }, 300);

  useEffect(() => {
    handleGetComplaints();
  }, [page, pageSize]);

  useEffect(() => {
    if (error === 403) {
      errorToast("دسترسی شما به این صفحه مسدود شده است.");
    }
  }, [error]);

  return (
    <>
      <Box sx={{ mt: 1 }} dir="rtl">
        <Grid2 spacing={2}>
          <Grid2 sm={12}>
            {firstLoad ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : complaints?.length > 0 ? (
              <Box sx={{ width: "100%", display: "grid" }}>
                <DataGrid
                  rows={complaints}
                  columns={columns}
                  pageSizeOptions={[pageSize]}
                  pagination
                  page
                  paginationMode="server"
                  disableColumnMenu
                  autoPageSize
                  rowCount={totalCount}
                  loading={isLoading}
                  onPaginationModelChange={(newPage) => {
                    handlePageChange(newPage);
                  }}
                />
              </Box>
            ) : error === 403 ? (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                دسترسی شما به این صفحه مسدود شده است.
              </Box>
            ) : (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                تیکتی یافت نشد.
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default ComplaintsListPage;
