import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "jalali-moment";
import {
  API_GET_TICKET_MESSAGES,
  API_SEND_TICKET_MESSAGE,
  getGlobalHeader,
} from "../../api";
import { errorToast, successToast } from "../../helpers/utils";
import { Controller, useForm } from "react-hook-form";

function TicketMessagesPage() {
  const params = useParams();
  const id = params.id;
  const structure_id = params.structureID;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  // State variable to manage the visibility of a modal, initialized as false
  const [modalOpen, setModalOpen] = useState(false);

  const getTicketData = async () => {
    // console.log(id);

    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_TICKET_MESSAGES(id), {
        headers: { ...getGlobalHeader() },
      });

      setData(response.data.results);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const handleSubmitForm = async (formData) => {
    try {
      setIsLoading(true);

      const response = await axios.post(API_SEND_TICKET_MESSAGE(), formData, {
        headers: { ...getGlobalHeader() },
      });

      successToast("پیام شما با موفقیت ارسال شد.");

      getTicketData();

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getTicketData();
  }, []);

  return (
    <>
      <Box sx={{ maxWidth: "80%", mx: "auto", mt: 1 }} dir="rtl">
        <Box sx={{ marginBottom: "5px" }}>
          <ul
            style={{
              listStyle: "none",
              padding: "0",
              margin: "0",
            }}
          >
            {data?.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    overflow: "hidden",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      background:
                        item.sender_role === "Admin"
                          ? "#eee"
                          : item.sender_role === "Expert"
                          ? "#86BB71"
                          : "#94C2ED",
                      padding: "18px 20px",
                      lineHeight: "26px",
                      fontSize: "16px",
                      borderRadius: "7px",
                      marginBottom: "30px",
                      // width: "70%",
                      position: "relative",
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft:
                        item.sender_role === "Admin" ||
                        item.sender_role === "Expert"
                          ? "auto"
                          : "",
                    }}
                  >
                    <div
                      className={`message ${
                        item.message_type !== "Admin" ? "others" : ""
                      }`}
                      style={{ textAlign: "right" }}
                    >
                      <div>
                        {item.sender
                          ? item.sender.first_name + " " + item.sender.last_name
                          : ""}
                        {item.sender_role === "Admin"
                          ? " (ناظر)"
                          : item.sender_role === "Expert"
                          ? " (کارشناس)"
                          : ""}
                        : {item.text}
                      </div>

                      <small
                        style={{
                          marginTop: "10px",
                          marginRight: "auto",
                        }}
                      >
                        ارسال شده در:{" "}
                        {moment
                          .from(item.timestamp, "YYYY/MM/DD")
                          .locale("fa")
                          .format("DD MMMM YYYY، ساعت HH:mm")}
                      </small>
                    </div>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Box>

        <Box
          sx={{ marginTop: "60px" }}
          component="form"
          noValidate
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <Controller
                  name="chat_id"
                  control={control}
                  defaultValue={id}
                  type="hidden"
                  render={({ field }) => (
                    <TextField label="chat_id" {...field} disabled />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  id="message"
                  label="توضیحات"
                  multiline
                  rows={3}
                  {...register("message")}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}></Grid>

            <Grid item xs={12} sm={9}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                type="submit"
              >
                ارسال
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default TicketMessagesPage;
