import { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { API_CREATE_USER, getGlobalHeader, global_header } from "../../api";
import { errorToast, successToast } from "../../helpers/utils";

const UserCreatePage = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateUserForm = async (formData) => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const response = await axios.post(API_CREATE_USER, formData, {
        headers: { ...getGlobalHeader() },
      });

      if (response.data.Result !== false) {
        successToast("کاربر با موفقیت ثبت شد!");

        setTimeout(() => {
          window.location.href = `/users`;

          setIsLoading(false);
        }, 1000);
      } else {
        errorToast(response.data.msg);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  return (
    <Box
      sx={{ mt: 1 }}
      dir="rtl"
      component="form"
      noValidate
      onSubmit={handleSubmit(handleCreateUserForm)}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={4}>
          <FormControl fullWidth>
            <TextField
              label="نام"
              margin="none"
              autoComplete="text"
              autoFocus
              disabled={isLoading}
              error={errors.fullname && true}
              {...register(`first_name`, {
                required: true,
              })}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={4}>
          <FormControl fullWidth>
            <TextField
              label="نام خانوادگی"
              margin="none"
              autoComplete="text"
              autoFocus
              disabled={isLoading}
              error={errors.fullname && true}
              {...register(`last_name`, {
                required: true,
              })}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={4}>
          <FormControl fullWidth>
            <TextField
              label="نام کاربری"
              margin="none"
              autoComplete="text"
              autoFocus
              disabled={isLoading}
              error={errors.fullname && true}
              {...register(`username`, {
                required: true,
              })}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <TextField
              label="رمز عبور*"
              margin="none"
              autoComplete="text"
              type="password"
              disabled={isLoading}
              error={errors.password && true}
              {...register(`password`, {
                required: true,
              })}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <TextField
              label="موبایل"
              fullWidth
              autoComplete="text"
              type="tel"
              error={errors.phone_number && true}
              {...register("phone_number", {
                minLength: {
                  value: 10,
                  message: "شماره موبایل باید حداقل 10 کاراکتر باشد.",
                },
                maxLength: {
                  value: 11,
                  message: "شماره موبایل نباید بیشتر از 11 کاراکتر باشد.",
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: "شماره موبایل باید یک مقدار صحیح 0 یا بالاتر باشد.",
                },
              })}
              helperText={errors.phone_number && errors.phone_number.message}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <TextField
              label="نام پدر"
              margin="none"
              autoComplete="text"
              autoFocus
              disabled={isLoading}
              error={errors.father_name && true}
              {...register(`father_name`, {
                required: true,
              })}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <TextField
              label="شماره دانشجویی"
              margin="none"
              fullWidth
              autoComplete="text"
              disabled={isLoading}
              error={errors.student_number && true}
              {...register(`student_number`)}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <TextField
              label="کد ملی"
              margin="none"
              fullWidth
              autoComplete="text"
              disabled={isLoading}
              error={errors.national_id && true}
              {...register(`national_id`)}
            />
          </FormControl>
        </Grid2>

        <Grid2 item size={4}>
          <FormControl fullWidth>
            <InputLabel>نقش</InputLabel>

            <Controller
              name="role"
              control={control}
              defaultValue="user"
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="Admin">مدیر</MenuItem>
                  <MenuItem value="Expert">کارشناس</MenuItem>
                  <MenuItem value="Customer">کاربر</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid2>
      </Grid2>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        disabled={isLoading}
        sx={{
          mt: 3,
          mb: 2,
        }}
      >
        ایجاد کاربر
      </Button>
    </Box>
  );
};

export default UserCreatePage;
