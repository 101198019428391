import {
  Button,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import moment from "jalali-moment";
import {
  getGlobalHeader,
  API_GET_COMPLAINT_DETAIL,
  API_UPDATE_REQUEST,
} from "../../api";
import { errorToast, successToast } from "../../helpers/utils";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

function ComplaintSinglePage() {
  const params = useParams();
  const id = params.id;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getRequestData = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(API_GET_COMPLAINT_DETAIL(id), {
        headers: {
          ...getGlobalHeader(),
        },
      });

      setData(response.data.complaint_detail);

      setIsLoading(false);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);

      console.error("Error:", error.message);
    }
  };

  const handleChangeComplaintStatus = async (formData) => {
    try {
      setIsLoading(true);

      const response = await axios.post(API_UPDATE_REQUEST(), formData, {
        headers: { ...getGlobalHeader() },
      });

      successToast("درخواست شما با موفقیت ثبت شد.");

      getRequestData();

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      setIsLoading(false);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <>
      <Box sx={{ maxWidth: "100%", mx: "auto", mt: 1 }} dir="rtl">
        {data && (
          <Box sx={{ mb: 4 }}>
            <Grid2 container>
              <Grid2 size={6}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>تاریخ ایجاد:</strong>{" "}
                  {moment
                    .from(data.file_create_time, "YYYY/MM/DD")
                    .locale("fa")
                    .format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Grid2>

              <Grid2 size={6}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>وضعیت شکایت:</strong>{" "}
                  {data.status === "in_review"
                    ? "در حال بررسی"
                    : data.status === "approved"
                    ? "تایید"
                    : data.status === "unsuccsessful"
                    ? "عدم تایید"
                    : data.status}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>ایجادکننده شکایت: </strong>
                  {data.user?.first_name + " " + data.user?.last_name}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>شکایت از: </strong>{" "}
                  {data.expert?.first_name + " " + data.expert?.last_name}
                </Typography>
              </Grid2>

              <Grid2 size={3}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>بررسی شکایت توسط: </strong>
                  {data.reviewer?.first_name !== undefined
                    ? data.reviewer?.first_name + " " + data.reviewer?.last_name
                    : "هنوز مشخص نشده"}
                </Typography>
              </Grid2>

              <Grid2 size={12}>
                <Box
                  sx={{
                    background: "#eee",
                    padding: "15px",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="body1">
                    <strong>توضیحات کاربر:</strong> {data.content}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        )}

        <Box
          sx={{ mb: 4 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(handleChangeComplaintStatus)}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <Typography variant="h6" gutterBottom>
                <strong>تغییر وضعیت شکایت</strong>
              </Typography>
            </Grid2>

            <Grid2 size={3}>
              <FormControl fullWidth>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={id}
                  render={({ field }) => (
                    <TextField label="id" {...field} disabled />
                  )}
                />
              </FormControl>
            </Grid2>

            <Grid2 size={9}>
              <FormControl fullWidth>
                <InputLabel>وضعیت</InputLabel>

                <Controller
                  name="status"
                  control={control}
                  type="text"
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select {...field} defaultValue={[]}>
                      <MenuItem value="in_review">در حال بررسی</MenuItem>
                      <MenuItem value="approved">تایید</MenuItem>
                      <MenuItem value="unsuccsessful">عدم تایید</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid2>

            <Grid2 size={12}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                type="submit"
              >
                ارسال
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </>
  );
}

export default ComplaintSinglePage;
