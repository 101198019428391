import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"; // Import service worker registration
import "../src/assets/scss/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>
);

// Register the service worker to enable PWA features
serviceWorkerRegistration.register();
