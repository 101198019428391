import {
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  API_GET_EXPERT_REQUESTS,
  API_GET_ADMIN_REQUESTS,
  getGlobalHeader,
} from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useForm } from "react-hook-form";
import { errorToast, successToast } from "../../helpers/utils";
import { red } from "@mui/material/colors";
import { Cookies } from "react-cookie";
import moment from "jalali-moment";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";

const RequestsListPage = () => {
  const { user } = useAuth();

  const [firstLoad, isFirstLoad] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1); // Assume at least 1 page
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      field: "actions",
      headerName: "عملیات",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.id && (
              <Tooltip title="مشاهده درخواست">
                <Link to={`/requests/show/${params.row.id}`}>
                  <IconButton aria-label="showRequest">
                    <VisibilityIcon
                      index={params.row.id}
                      sx={{ color: "#999" }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "file_number",
      headerName: "شماره پرونده",
      width: 200,
    },
    {
      field: "title",
      headerName: "عنوان درخواست",
      width: 150,
      valueGetter: (title) =>
        title === "upload_documents"
          ? "آپلود مدارک"
          : title === "dorm"
          ? "خوابگاه"
          : title === "objected_to_score"
          ? "اعتراض به نمره"
          : title === "graduation"
          ? "فارغ التحصیلی"
          : title,
    },
    {
      field: "user",
      headerName: "نام درخواست‌دهنده",
      width: 250,
      valueGetter: (user) => user && user.first_name + " " + user.last_name,
    },
    {
      field: "status",
      headerName: "وضعیت",
      width: 200,
      valueGetter: (status) =>
        status === "in_review"
          ? "در حال بررسی"
          : status === "not_review"
          ? "بررسی نشده"
          : status === "Closed by Admin"
          ? "بسته شده توسط مدیر"
          : status,
    },
    {
      field: "create_time",
      headerName: "آخرین به‌روزرسانی تیکت",
      width: 200,
      valueGetter: (create_time) =>
        create_time &&
        moment
          .from(create_time, "YYYY/MM/DD")
          .locale("fa")
          .format("HH:mm - YYYY/MM/DD"),
    },
  ];

  const handleGetTickets = async () => {
    const stringified = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
      },
      {
        skipEmptyString: true,
      }
    );

    try {
      setIsLoading(true);

      // Make a request to the server using Axios
      const response = await axios.get(
        user.role === "Admin"
          ? API_GET_ADMIN_REQUESTS(stringified)
          : API_GET_EXPERT_REQUESTS(stringified),
        {
          headers: { ...getGlobalHeader() },
        }
      );

      // Handle the response as needed
      setTickets(response.data.results);
      setTotalCount(response.data.count);
      setTotalPages(Math.ceil(response.data.count / pageSize)); // Assuming the API returns a 'count' field for total results
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }

    isFirstLoad(false);
  };

  useEffect(() => {
    if (!user !== true) {
      handleGetTickets();
    }
  }, [user, page, pageSize]);

  return (
    <>
      <Box sx={{ mt: 1 }} dir="rtl">
        <Grid2 spacing={2}>
          <Grid2 sm={12}>
            {firstLoad ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : tickets?.length > 0 ? (
              <Box sx={{ width: "100%", display: "grid" }}>
                <DataGrid
                  rows={tickets}
                  columns={columns}
                  pageSizeOptions={[pageSize]}
                  pagination
                  page
                  paginationMode="server"
                  disableColumnMenu
                  autoPageSize
                  rowCount={totalCount}
                  loading={isLoading}
                  onPaginationModelChange={(newPage) => {
                    setPage(newPage.page + 1); // DataGrid uses 0-based index, API expects 1-based
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                درخواستی یافت نشد.
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default RequestsListPage;
