import {
  Button,
  CircularProgress,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  API_ADMIN_BILLBOARDS,
  API_DELETE_USER,
  API_EDIT_USER,
  API_GET_USERS,
  API_GET_USERS_LIST,
  API_UPDATE_USER,
  API_UPDATE_USER_STRUCTURES,
  getGlobalHeader,
  global_header,
} from "../../api";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { errorToast, successToast } from "../../helpers/utils";
import EditUserModalComponent from "./components/EditUserModalComponent";
import { red } from "@mui/material/colors";
import queryString from "query-string";

const UsersListPage = () => {
  const {
    register: registerEditForm,
    control: controlEditForm,
    handleSubmit: handleSubmitEditForm,
    errors: errorsEditForm,
    reset: resetEditForm,
  } = useForm();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(null);

  const columns = [
    {
      field: "actions",
      headerName: "عملیات",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const userId = params.row.id;

        return (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title="ویرایش کاربر">
              <IconButton
                aria-label="editUser"
                onClick={() => handleOpenEditModal(params.row.id)}
              >
                <EditIcon index={params.row.id} sx={{ color: "#999" }} />
              </IconButton>

              {userId && (
                <EditUserModalComponent
                  isOpen={openEditModal === userId}
                  onClose={handleCloseEditModal}
                  onSubmit={handleSubmitEditForm(submitUserEditForm)}
                  isLoading={isLoading}
                  defaultValues={{
                    id: userId,
                    first_name: params.row.first_name,
                    last_name: params.row.last_name,
                    national_id: params.row.national_id,
                    student_number: params.row.student_number,
                    phone_number: params.row.phone_number,
                    father_name: params.row.father_name,
                  }}
                  errors={errorsEditForm}
                  register={registerEditForm}
                  control={controlEditForm}
                />
              )}
            </Tooltip>

            <IconButton
              aria-label="DeleteGame"
              onClick={() => handleDeleteUser(params.row.id)}
            >
              <DeleteIcon index={params.row.id} sx={{ color: "#f99" }} />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "fullname",
      headerName: "نام و نام خانوادگی",
      width: 300,
      renderCell: (params) =>
        params && params.row.first_name + " " + params.row.last_name,
    },
    {
      field: "role",
      headerName: "نقش",
      width: 200,
      valueGetter: (role) =>
        role === "Customer"
          ? "کاربر"
          : role === "Expert"
          ? "کارشناس"
          : role === "Admin"
          ? "مدیر"
          : role,
    },
    {
      field: "phone_number",
      headerName: "شماره تماس",
      width: 200,
    },
    {
      field: "national_id",
      headerName: "کد ملی",
      width: 200,
    },
    {
      field: "father_name",
      headerName: "نام پدر",
      width: 200,
    },
  ];

  const handleGetUsersData = async () => {
    const stringified = queryString.stringify(
      {
        page: page + 1,
        page_size: 10,
      },
      {
        skipEmptyString: true,
      }
    );

    try {
      const response = await axios.get(API_GET_USERS_LIST(stringified), {
        headers: { ...getGlobalHeader() },
      });

      // Handle the response as needed
      setUsers(response.data.results);

      setTotalCount(response.data.count);
    } catch (error) {
      // Handle errors, e.g., validation errors or network issues
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }

    setIsFirstLoad(false);
  };

  const submitUserEditForm = async (formData) => {
    setIsLoading(true);

    const stringified = queryString.stringify(formData, {
      skipEmptyString: true,
    });

    try {
      setIsLoading(true);

      const response = await axios.post(API_UPDATE_USER(), formData, {
        headers: { ...getGlobalHeader() },
      });

      successToast("کاربر با موفقیت ویرایش شد!");

      setTimeout(() => {
        resetEditForm();

        setOpenEditModal(null);

        handleGetUsersData();

        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setIsLoading(false);

      if (error.response && error.response.data) {
        if (error.response.data.password) {
          error.response.data.password.map((errorMessage) => {
            return errorToast("رمز عبور: " + errorMessage);
          });
        }

        if (error.response.data.username) {
          error.response.data.username.map((errorMessage) => {
            return errorToast("نام کاربری: " + errorMessage);
          });
        }
      } else {
        console.error("Unexpected error occurred:", error);
        // Display a generic error message if the password field error is not found
        errorToast("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm("آیا از حذف مطمئن هستید؟")) {
      try {
        // 👇️ const data: GetUsersResponse
        const { data } = await axios.post(
          `${API_DELETE_USER}`,
          {
            id,
          },
          {
            headers: {
              ...getGlobalHeader(),
            },
          }
        );
        setUsers(users.filter((user, index) => user.id !== id));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error);
        } else {
          console.log("unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    } else {
      return;
    }
  };
  const handleOpenEditModal = (id) => {
    setOpenEditModal(id);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(null);
  };

  useEffect(() => {
    handleGetUsersData();
  }, [page]);

  useEffect(() => {
    resetEditForm();
  }, [openEditModal]);

  return (
    <>
      <Box
        component="span"
        sx={{
          px: 3,
          py: 2,
          m: 2,
          border: "1px solid #ccc",
          display: "inline-block",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Link to="/users/create">
            <Button variant="contained" color="primary">
              ایجاد کاربر جدید
            </Button>
          </Link>
        </Stack>
      </Box>

      <Box sx={{ mt: 1 }} dir="rtl">
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            {isFirstLoad ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : users?.length > 0 ? (
              <Box sx={{ height: 630, width: "100%", display: "grid" }}>
                <DataGrid
                  rows={users}
                  columns={columns}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  loading={isLoading}
                  rowCount={totalCount}
                  autoPageSize
                  pagination
                  page
                  paginationMode="server"
                  onPaginationModelChange={(newPage) => {
                    setPage(newPage.page);
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ bgcolor: red[200], padding: "5px" }}>
                کاربری یافت نشد.
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default UsersListPage;
